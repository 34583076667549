import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import Page from '../../components/page'
import { InfoNote, WarningNote } from '../../components/highlight'
import withRoot from '../../withRoot'

import {
  Paper,
  Typography,
  Collapse,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core'

import {
  LayersTwoTone,
  DashboardTwoTone,
  AddPhotoAlternateTwoTone,
  GradientTwoTone,
  TitleTwoTone,
  RemoveTwoTone,
  LooksTwoTone,
  ImageTwoTone,
  RadioButtonUncheckedTwoTone,
  CheckBoxOutlineBlankTwoTone,
  ExpandLess,
  ExpandMore,
} from '@material-ui/icons'

const styles = theme => ({
  listTitle: {
    paddingTop: theme.spacing(5),
  },
  desc: {
    padding: theme.spacing(3, 2)
  },
  desclist: {
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(2),
  }
})

@withRoot
@withStyles(styles)
export default class Elements extends React.Component {
  constructor(props) {
    super(props)
    this.state = { element: '' }
    this.handleClick = this.handleClick.bind(this)
  }

  handleClick(id) {
    this.setState({ element: (this.state.element === id ? '' : id) })
  }

  render() {
    const { classes } = this.props
    const { element } = this.state

    return (
      <Page toggleTheme={this.props.toggleTheme} title="Elements">
        <List component="div">
          <Typography>
            The creator permits to create the basic svg elements supported by Fitbit OS listed below.
          </Typography>
          <Typography align='center' variant="h4" component="h4" className={classes.listTitle}>
            Simple Elements
          </Typography>
          <List component="div" disablePadding>
            <ListItem id="rectangle" button onClick={() => this.handleClick("rectangle")}>
              <ListItemIcon><CheckBoxOutlineBlankTwoTone /></ListItemIcon>
              <ListItemText primary="Rectangle" />
              {element === 'rectangle' ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={element === 'rectangle'} timeout="auto" unmountOnExit>
              <Paper className={classes.desc}>
                <Typography>
                  The Rectangle allows you to draw rectangles on the screen.
                </Typography>
                <List component="div" className={classes.desclist}>
                  <ListItemText primary="x, y" secondary="position of the left, top-most corner." />
                  <ListItemText primary="width, height" secondary="specify the rectangle’s size." />
                  <ListItemText primary="rotation" secondary="specifies the rectangle rotation angle (in degrees)." />
                  <ListItemText primary="color" secondary="specifies the rectangle fill color." />
                  <ListItemText primary="opacity" secondary="specifies the opacity of the rectangle (between 0 and 1)." />
                </List>
              </Paper>
            </Collapse>
            <ListItem id="circle" button onClick={() => this.handleClick("circle")}>
              <ListItemIcon><RadioButtonUncheckedTwoTone /></ListItemIcon>
              <ListItemText primary="Circle" />
              {element === 'circle' ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={element === 'circle'} timeout="auto" unmountOnExit>
              <Paper className={classes.desc}>
                <Typography>
                  The Circle allows you to draw circles on the screen.
                </Typography>
                <List component="div" className={classes.desclist}>
                  <ListItemText primary="x, y" secondary="coordinates of the center x and center y of the circle." />
                  <ListItemText primary="radius" secondary="radius of the circle." />
                  <ListItemText primary="rotation" secondary="specifies the rectangle rotation angle (in degrees)." />
                  <ListItemText primary="color" secondary="specifies the circle fill color." />
                  <ListItemText primary="opacity" secondary="specifies the opacity of the circle (between 0 and 1)." />
                </List>
              </Paper>
            </Collapse>
            <ListItem id="arc" button onClick={() => this.handleClick("arc")}>
              <ListItemIcon><LooksTwoTone /></ListItemIcon>
              <ListItemText primary="Arc" />
              {element === 'arc' ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={element === 'arc'} timeout="auto" unmountOnExit>
              <Paper className={classes.desc}>
                <Typography>
                  The Arc allows you to draw arcs on the screen.
                </Typography>
                <List component="div" className={classes.desclist}>
                  <ListItemText primary="x, y" secondary="coordinates of the center x and center y of the arc." />
                  <ListItemText primary="radius" secondary="radius of the arc." />
                  <ListItemText primary="start angle" secondary="start angle in degrees, with 0 at 12 o’clock." />
                  <ListItemText primary="sweep angle" secondary="length of the arc in degrees." />
                  <ListItemText primary="arc width" secondary="arc thickness in pixels. Maximum 31." />
                  <ListItemText primary="color" secondary="specifies the arc fill color." />
                  <ListItemText primary="opacity" secondary="specifies the opacity of the arc (between 0 and 1)." />
                </List>
              </Paper>
            </Collapse>
            <ListItem id="line" button onClick={() => this.handleClick("line")}>
              <ListItemIcon><RemoveTwoTone /></ListItemIcon>
              <ListItemText primary="Line" />
              {element === 'line' ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={element === 'line'} timeout="auto" unmountOnExit>
              <Paper className={classes.desc}>
                <Typography>
                  The Line element draws a line between two pairs of coordinates.
                </Typography>
                <List component="div" className={classes.desclist}>
                  <ListItemText primary="x1, y1" secondary="the start point of the line." />
                  <ListItemText primary="x2, y2" secondary="the end point of the line." />
                  <ListItemText primary="width" secondary="the thickness of the line. Maximum 32." />
                  <ListItemText primary="round" secondary="specifies if the line ends are round." />
                  <ListItemText primary="color" secondary="specifies the line fill color." />
                  <ListItemText primary="opacity" secondary="specifies the opacity of the line (between 0 and 1)." />
                </List>
              </Paper>
            </Collapse>
            <ListItem id="image" button onClick={() => this.handleClick("image")}>
              <ListItemIcon><ImageTwoTone /></ListItemIcon>
              <ListItemText primary="Image" />
              {element === 'image' ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={element === 'image'} timeout="auto" unmountOnExit>
              <Paper className={classes.desc}>
                <Typography>
                  The Image allows you to draw images on the screen.
                </Typography>
                <List component="div" className={classes.desclist}>
                  <ListItemText primary="x, y" secondary="coordinates of the center of the image." />
                  <ListItemText primary="rotation" secondary="specifies the image rotation angle (in degrees)." />
                  <ListItemText primary="opacity" secondary="specifies the opacity of the image (between 0 and 1)." />
                  <ListItemText primary="grayscale" secondary="activates the image grayscale magic and permits to colorize B&W images." />
                  <ListItemText primary="color" secondary="specifies the color of the image (if grayscale mode is active)." />
                </List>
              </Paper>
            </Collapse>
            <ListItem id="picker" button onClick={() => this.handleClick("picker")}>
              <ListItemIcon><AddPhotoAlternateTwoTone /></ListItemIcon>
              <ListItemText primary="Image Picker" />
              {element === 'picker' ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={element === 'picker'} timeout="auto" unmountOnExit>
              <Paper className={classes.desc}>
                <Typography>
                  The Image Picker allows you to put an image place holder on the screen.<br />
                  In the settings of the clockface, your user will be able to choose the final image displayed on the watch.
                </Typography>
                <List component="div" className={classes.desclist}>
                  <ListItemText primary="x, y" secondary="coordinates of the center of the image." />
                  <ListItemText primary="width, height" secondary="specify the final image’s size." />
                  <ListItemText primary="rotation" secondary="specifies the image rotation angle (in degrees)." />
                  <ListItemText primary="opacity" secondary="specifies the opacity of the image (between 0 and 1)." />
                </List>
                <InfoNote>In the editor, you will see a random image, this is not the image used on the device.</InfoNote>
              </Paper>
            </Collapse>
            <ListItem id="text" button onClick={() => this.handleClick("text")}>
              <ListItemIcon><TitleTwoTone /></ListItemIcon>
              <ListItemText primary="Text" />
              {element === 'text' ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={element === 'text'} timeout="auto" unmountOnExit>
              <Paper className={classes.desc}>
                <Typography>
                  The Text element draws a single line of text.
                </Typography>
                <List component="div" className={classes.desclist}>
                  <ListItemText primary="x, y" secondary="coordinates of the anchor point." />
                  <ListItemText primary="text" secondary="specifies the text to display." />
                  <ListItemText primary="font size" secondary="specifies the font size in pixels." />
                  <ListItemText primary="font family" secondary="specifies the font to use." />
                  <ListItemText primary="alignment" secondary="specifies the text anchor (start, middle, end)." />
                  <ListItemText primary="letter spacing" secondary="specifies the letter spacing in pixels (default is 0)." />
                  <ListItemText primary="color" secondary="color of the text." />
                  <ListItemText primary="rotation" secondary="specifies the text rotation angle (in degrees)." />
                  <ListItemText primary="opacity" secondary="specifies the opacity of the text (between 0 and 1)." />
                </List>
              </Paper>
            </Collapse>
            <ListItem id="gradient" button onClick={() => this.handleClick("gradient")}>
              <ListItemIcon><GradientTwoTone /></ListItemIcon>
              <ListItemText primary="Gradient" />
              {element === 'gradient' ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={element === 'gradient'} timeout="auto" unmountOnExit>
              <Paper className={classes.desc}>
                <Typography>
                  The Gradient element fills a rectangle with a color texture, without the use of a bitmap.<br />
                  It can blend two colors when the gradient type is linear or radial
                </Typography>
                <List component="div" className={classes.desclist}>
                  <ListItemText primary="x, y" secondary="position of the left, top-most corner." />
                  <ListItemText primary="width, height" secondary="specify the gradient’s size." />
                  <ListItemText primary="type" secondary="specifies the type of the gradient (linear or radial)." />
                  <ListItemText primary="rotation" secondary="specifies the gradient’s rotation angle (in degrees)." />
                  <ListItemText primary="color1" secondary="specifies the gradient color1." />
                  <ListItemText primary="opacity1" secondary="specifies the opacity1 of the gradient (between 0 and 1)." />
                  <ListItemText primary="color2" secondary="specifies the gradient color2." />
                  <ListItemText primary="opacity2" secondary="specifies the opacity2 of the gradient (between 0 and 1)." />
                </List>
              </Paper>
            </Collapse>
          </List>
          <Typography align='center' variant="h4" component="h4" className={classes.listTitle}>
            Advanced Elements
          </Typography>
          <List component="div" disablePadding>
            <ListItem id="group" button onClick={() => this.handleClick("group")}>
              <ListItemIcon><DashboardTwoTone /></ListItemIcon>
              <ListItemText primary="Group" />
              {element === 'group' ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={element === 'group'} timeout="auto" unmountOnExit>
              <Paper className={classes.desc}>
                <Typography>
                  The Group element can be used to translate (move), rotate, all of the elements it contains.<br />
                  The transformations are applied to the coordinates of its child elements.
                </Typography>
                <List component="div" className={classes.desclist}>
                  <ListItemText primary="x, y" secondary="position of the origin of the group which is also the rotation center." />
                  <ListItemText primary="rotation" secondary="specifies the group rotation angle (in degrees)." />
                  <ListItemText primary="opacity" secondary="specifies the opacity of the group (between 0 and 1)." />
                </List>
                <WarningNote>
                  <Typography>
                    The group element is one of the most complex elements to use for beginners.<br />
                    It is important to understand that the child elements of the group are positionned relatively to its origin.<br />
                    If you want to put an child element at the origin of the group, its (x,y) must be (0,0).
                  </Typography>
                </WarningNote>
              </Paper>
            </Collapse>
            <ListItem id="mask" button onClick={() => this.handleClick("mask")}>
              <ListItemIcon><LayersTwoTone /></ListItemIcon>
              <ListItemText primary="Mask" />
              {element === 'mask' ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            <Collapse in={element === 'mask'} timeout="auto" unmountOnExit>
              <Paper className={classes.desc}>
                <Typography>
                  In every Group element, you can add a Mask which can be used for visual effects.<br />
                  A mask is a group that can contain any graphical elements.<br />
                  Masking allows you to define visible and non-visible areas of images, allowing for seamless edges and creative layering.<br />
                  Masks enable common use cases such as circular avatars, rounded corners, or more complex custom shapes.
                </Typography>
                <InfoNote>A group that uses a mask will be painted through the mask. Only the overlapping areas will remain.</InfoNote>
                <WarningNote>If you understand how to use masks, congratulations, you are now a FitFace expert :-)</WarningNote>
              </Paper>
            </Collapse>
          </List>
        </List>
      </Page>
    )
  }
}
